var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"5ooXp9dFf7NwEVpq7NzyF"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

import { getEnv } from "@/utils";

import { getTracesSamplerRate, SENTRY_DSN } from "./scripts/sentry";

Sentry.init({
  dsn: SENTRY_DSN,
  // Replay may only be enabled for the client-side
  integrations: [Sentry.replayIntegration(), new Sentry.BrowserProfilingIntegration()],
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  enabled: process.env.NODE_ENV !== "development",
  // TODO use env variable later, the env variable not exist in browser
  environment: getEnv(),
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampler: (samplingContext) => {
    return getTracesSamplerRate(samplingContext);
  },
  profilesSampleRate: 1.0,
  ignoreErrors: ["Non-Error promise rejection captured"],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
